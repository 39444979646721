.user-panel {
	position: absolute;
	top: $default-spacing;
	//right: 200px;
	right: $default-spacing;

	img {
		float: left;
		width: 32px;
		height: auto;
		border-radius: 16px;
		cursor: pointer;
	}

	.nickname {
		float: left;
		margin-left: $default-spacing;
		line-height: 32px;
		cursor: pointer;
	}

	ul {
		display: none;
	
		position: absolute;
		left: 32px + $default-spacing;
		top: 40px;
	
		li {
			cursor: pointer;
		}
	}

	&.active {
		ul {
			display: block;
		}
	}
}