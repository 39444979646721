@import "base/vars";
@import "base/reset";
@import "base/grid";
@import "base/utils";

@import "base/typography";
@import "base/panel";
@import "base/alert";
@import "base/form";
@import "base/button";
@import "base/table";
@import "base/user-panel";

body {
	background: #111 url('/assets/images/darknoise.png');
}

.view-backend .content {
	min-height: calc(100vh - 165px);
	padding: $default-spacing*2 0px;
}

.view-login,
.view-password-forgot,
.view-password-reset,
.view-register,
.view-confirm {
	padding-top: $default-spacing*4;
}

.footer-login p {
	font-size: $text-font-size*0.9;

	a {
		margin-left: $default-spacing;
	}
}

.header {
	padding: $default-spacing*0.75;
    //background-color: rgba(0,0,0,0.4);
	
	.logo {
		width: auto;
		height: 90px;
		margin-left: 50%;
		transform: translate(-50%, 0px);
	}

}

.user-panel {
	right: 100px;
}

.navigation {
	padding: $default-spacing*1.25 $default-spacing*2;
	background: #2245c9;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
	overflow: hidden;

	ul {
		li {
			display: block;
			float: left;
			position: relative;
			font-size: $base-font-size*1.25;
			line-height: $base-font-size;
			padding: 0px $default-spacing*2 0px 0px;
			font-family: $headline-font-family;
			font-weight: 300;
			text-transform: uppercase;

			a {
				color: #fff;

				&:after {
					content: "›";
					font-size: $base-font-size*2;
					position: absolute;
					right: 10px;
					top: -2px;
				}
			}

			&:last-child {
				padding: 0px;

				a:after {
					content: "";
				}
			}
		}
	}
}

.intro {
	h1 {
		font-family: $headline-font-family;
		font-size: $base-font-size*2;
		line-height: $base-font-size*2.5;
		text-align: center;
			font-weight: 300;
	}
}


@media screen and (min-width: $screen-md-min) {
	.intro {
		padding: $default-spacing*2;
		width: 80%;
		margin: 0 auto;

		h1 {
			font-size: $base-font-size*3;
			line-height: $base-font-size*4;
			margin-bottom: $default-spacing * 5;
		}
	}
}

.footer {
    background-color: #181818;
    min-height: 120px;
}

.image-editor {

	&.selected {
		.wrapper {
			.top, .left, .right, .bottom {
				//background: rgba(0,0,0,0.8);
			}
			.selection {
				display: block;
			}
		}
	}

	.wrapper {
		position: relative;
		width: 512px;
		height: 512px;
		overflow: hidden;
		background: #fff;

		.overlay {
			display: block;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			//transform: translate(-50%,-50%);
			z-index: 500;
		}

		.top, .left, .right, .bottom {
			position: absolute;
			z-index: 200;

		}


		.top {
			top: 0px;
			left: 0px;
			width: 512px;
			height: 64px;
		}

		.left {
			top: 64px;
			left: 0px;
			width: 64px;
			height: 512px - 128px;
		}

		.right {
			top: 64px;
			right: 0px;
			width: 64px;
			height: 512px - 128px;
		}

		.bottom {
			bottom: 0px;
			left: 0px;
			width: 512px;
			height: 64px;
		}

		.selection {
			background: rgba(255,0,0,0.1);
			position: absolute;
			display: none;
			z-index: 300;

			.corner {
				position: absolute;
				width: 25px;
				height: 25px;
				border: 3px solid rgba(0,0,0,0.9);

				&.top-left {
					top: 0px;
					left: 0px;
					border-right: 0px;
					border-bottom: 0px;
				}

				&.top-right {
					top: 0px;
					right: 0px;
					border-left: 0px;
					border-bottom: 0px;
				}
			
				&.bottom-left {
					bottom: 0px;
					left: 0px;
					border-right: 0px;
					border-top: 0px;
				}

				&.bottom-right {
					bottom: 0px;
					right: 0px;
					border-left: 0px;
					border-top: 0px;
				}
			}
		}

		/*
		img {
			position: absolute;
			width: 100%;
			height: auto;
			top: 50%;
			left: 50%;
			z-index: 100;
			
			transform: translate(-50%,-50%);
		}*/

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}

	}
/*
	&.landscape,
	&.square {
		img {
			width: 100%;
			height: auto;
		}
	}

	&.portrait {
		img {
			width: auto;
			height: 100%;
		}
	}*/
}

.avatar-selector {
	li {
		display: inline-block;
		margin-right: 10px;
		cursor: pointer;
	}
}