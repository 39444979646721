body, p {
	font-family: $text-font-family;
	font-size: $text-font-size*0.9;
	font-weight: 400;
	color: $text-color;
	line-height: $text-font-size*1.4;

	small {
		font-size: $text-font-size * 0.7;
	}
}

@media screen and (min-width: $screen-md-min) {
	body, p {
		font-size: $text-font-size;

		small {
			font-size: $text-font-size * 0.8;
		}
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headline-font-family;	
	margin: $default-spacing/2 0px $default-spacing 0px;
	font-weight: 400;
	line-height: 1em;
}

h1, .h1 {
	font-size: $h1-font-size/2;
}

h2, .h2 {
	font-size: $h2-font-size/2;
}

h3, .h3 {
	font-size: $h3-font-size/2;
}

h4, .h4 {
	font-size: $h4-font-size/2;
}

h5, .h5 {
	font-size: $h5-font-size/2;
}

h6, .h6 {
	font-size: $h6-font-size/2;
}


@media screen and (min-width: $screen-md-min) {
	h1, .h1 {
		font-size: $h1-font-size*0.75;
	}

	h2, .h2 {
		font-size: $h2-font-size*0.75;
	}

	h3, .h3 {
		font-size: $h3-font-size*0.75;
	}

	h4, .h4 {
		font-size: $h4-font-size*0.75;
	}

	h5, .h5 {
		font-size: $h5-font-size*0.75;
	}

	h6, .h6 {
		font-size: $h6-font-size*0.75;
	}
}


@media screen and (min-width: $screen-lg-min) {
	h1, .h1 {
		font-size: $h1-font-size;
	}

	h2, .h2 {
		font-size: $h2-font-size;
	}

	h3, .h3 {
		font-size: $h3-font-size;
	}

	h4, .h4 {
		font-size: $h4-font-size;
	}

	h5, .h5 {
		font-size: $h5-font-size;
	}

	h6, .h6 {
		font-size: $h6-font-size;
	}
}

a, a:active, a:link, .link {
	color: $link-color;
	text-decoration: none;
}

a:hover {
	color: $link-color-hover;
	cursor: pointer;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-warning {
	color: $status-warning-color;
}

.text-error {
	color: $status-error-color;
}

.text-success {
	color: $status-success-color;
}

ul.list {
	list-style: disc outside none;
	padding: 0px 0px 0px 20px;
	margin: 0px 0px $default-spacing 0px;

	li {
		margin: 0px 0px $default-spacing/2 0px;
	}
}

p, ul, pre, ol {
	margin: 0px 0px $default-spacing 0px;
}

strong, b {
	font-weight: 700;
}

.intro {
	h1 {
		font-family: $headline-font-family;
		font-size: $base-font-size*4;
		line-height: $base-font-size*4;
		text-align: center;
		margin-bottom: $default-spacing * 5;
	}
}

@media screen and (min-width: $screen-md-min) {
	.intro {
		padding: $default-spacing*2;
		width: 80%;
		margin: 0 auto;

		h1 {
			font-size: $base-font-size*3;
			line-height: $base-font-size*4;
			font-weight: 300;
		}
	}
}