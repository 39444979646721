$base-font-size: 14px;

$text-font-family: 'Open sans', sans-serif;
$text-font-size: $base-font-size;
$text-color: #f0f0f0;

$link-color: #2245c9;
$link-color-hover: lighten($link-color, 20%);

$headline-font-family: 'Roboto', sans-serif;
$h1-font-size: $base-font-size*3;
$h2-font-size: $base-font-size*2.5;
$h3-font-size: $base-font-size*2;
$h4-font-size: $base-font-size*1.5;
$h5-font-size: $base-font-size*1;
$h6-font-size: $base-font-size*1;

/* default values */
$columns: 12;
$default-spacing: 15px;
$default-padding: $default-spacing;

//screen widths
$screen-xs-max: 480px;
$screen-sm-min: $screen-xs-max + 1;
$screen-sm-max: 768px;
$screen-md-min: $screen-sm-max + 1;
$screen-md-max: 1024px;
$screen-lg-min: $screen-md-max + 1;
$screen-lg-max: 1440px;
$screen-xl-min: $screen-lg-max + 1;

//container sizes
$container-xs-width: 100%;
$container-sm-width: 100%;
$container-md-width: $screen-md-min - 41px;
$container-lg-width: $screen-lg-min - 41px;
$container-xl-width: $screen-xl-min - 41px;

//colors
$primary-color: #2245c9;

$status-default-color: #e0e0e0;
$status-success-color: #27ae60;
$status-warning-color: #f39c12;
$status-error-color: #c0392b;

$ui-font-family: 'OpenSans', sans-serif;
$ui-font-size: $base-font-size;
$ui-font-color: $text-color;

$ui-button-color: $primary-color;
$ui-button-hover-color: lighten($ui-button-color, 10%);
$ui-button-text-color: #fff;
$ui-background:  #181818;


$ui-button-disabled-color: #ccc;
$ui-button-disabled-hover-color: lighten($ui-button-disabled-color, 10%);
$ui-button-disabled-text-color: #000;

$panel-default-bg:  #181818;

$modal-default-bg:  #181818;