
@import "vars";

table,
.table {

	width: 100%;

	&.table-striped {
		tr:nth-child(odd),
		.table--tr:nth-child(odd) {
			background: #111;
		}

		thead,
		.table--thead {
			tr:nth-child(odd),
			.table--tr:nth-child(odd) {
				background: transparent;
			}
		}
	}

	.table--thead {
		display: none;
	}

	.table--td {
		padding: $default-spacing/4;
	}

	.table--tr {
		padding: 0px 0px $default-spacing 0px;
		margin: 0px 0px $default-spacing 0px;
		border-bottom: 1px solid #ccc;
	}

	.table--tbody {
		.table--tr {
			&:last-child {
				padding: 0px;
				margin: 0px;
				border-bottom: none;
			}
		}
	}

	.table--th,
	th {
		font-weight: bold;
	}

	td, th {
		padding: $default-spacing/2;
	
		&.align-left {
			float: none;
			text-align: left;
		}

		&.align-right {
			float: none;
			text-align: right;
		}
		
		&.align-center {
			float: none;
			text-align: center;
		}
	}

	.table--label,
	.table--value {
		padding: 2px;
	}

	.table--label {
		font-weight: bold;
	}
}

@media screen and (min-width: $screen-md-min) {
	table,
	.table {
		display: table;

		/*th,
		.table--th {
			font-weight: bold;
			border-bottom: 1px solid #ccc;
		}*/

		.table--th,
		.table--td {
			display: block;
			padding: $default-spacing/2;
			float: left;
		}

		.table--tr {
			overflow: hidden;
			width: 100%;
			padding: 0px;
			margin: 0px 0px 4px 0px;
		}

		.table--thead {
			width: 100%;
			display: block;
		}
		
		.table--tbody {
			width: 100%;

			.table--label {
				display: none;
			}
			
			.table--tr {
				border: none;
			}
		}
	}
}