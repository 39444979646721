@import "vars";
@import "mixins/button";

button,
.btn,
a.btn {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
	background: $ui-button-color;
	color: $ui-button-text-color;
	border: 1px solid $ui-button-hover-color;
	border-radius: 1px;

	text-transform: uppercase;
	font-size: $ui-font-size*1.2;
	line-height: 0.9em;
	font-weight: 600;
	padding: $default-spacing/3*2 $default-spacing*2;
	cursor: pointer;
	display: inline-block;

	&:hover {
		background: $ui-button-hover-color;
		border-color: $ui-button-hover-color;
	}

	&.btn-xs {
		font-size: $ui-font-size;
		padding: $default-spacing/2 $default-spacing;
	}

	&.btn-upload {
		position: relative;
		overflow: hidden;

		input {
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
			padding: 0;
			font-size: 20px;
			cursor: pointer;
			opacity: 0;
			filter: alpha(opacity=0);
		}
	}

	&.btn-block {
		display: block;
		width: 100%;
		text-align: center;
		margin: 0px 0px $default-spacing 0px;
	}
}

button[disabled="disabled"],
.btn[disabled="disabled"],
button:disabled,
.btn:disabled,
.btn.disabled,
button.disabled {
	background: $ui-button-disabled-color;
	color: $ui-button-disabled-text-color;
	border: 1px solid $ui-button-disabled-hover-color;
	cursor: auto;
}

@media screen and (min-width: $screen-md-min) {
	button,
	.btn,
	a.btn {
		&.btn-xs {
			font-size: $ui-font-size*0.8;
			padding: $default-spacing/3 $default-spacing;
		}
	}
}

@media screen and (max-width: $screen-xs-max) {
	button,
	.btn,
	a.btn {
		&.btn-xs-block {
			@include make-btn-block();
		}
	}
}

@media screen and (max-width: $screen-sm-max) {
	button,
	.btn,
	a.btn {
		&.btn-sm-block {
			@include make-btn-block();
		}
	}
}

@media screen and (max-width: $screen-md-max) {
	button,
	.btn,
	a.btn {
		&.btn-md-block {
			@include make-btn-block();
		}
	}
}

@media screen and (max-width: $screen-lg-max) {
	button,
	.btn,
	a.btn {
		&.btn-lg-block {
			@include make-btn-block();
		}
	}
}