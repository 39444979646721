
@mixin make-columns($res, $columns, $prefix:"col") {
	@for $i from 1 through $columns {
		.#{$prefix}-#{$res}-#{$i} {
			width: ( 100% / $columns ) * $i;
		}

		.#{$prefix}-#{$res}-push-#{$i} {
			left: ( 100% / $columns ) * $i;
		}

		.#{$prefix}-#{$res}-pull-#{$i} {
			right: ( 100% / $columns ) * $i;
		}

		.#{$prefix}-#{$res}-offset-#{$i} {
			margin-left: ( 100% / $columns ) * $i;
		}
	}
}

@mixin make-container($width) {
	.container {
		@if $width == 100% {
			padding: 0px $default-spacing;
		} @else {
			width: $width;
			margin: 0 auto;
			padding: 0px;
		}
	}
}

@mixin make-visibility-helper($res) {
	.visible-#{$res}-block,
	.visible-#{$res} {
		display:block;
	}

	.visible-#{$res}-inline-block {
		display:inline-block;
	}

	.visible-#{$res}-inline {
		display:inline;
	}

	.hidden-#{$res} {
		display: none;
	}

	.invisible-#{$res} {
		visibility: hidden;
	}
}

@mixin make-flexrow() {
}