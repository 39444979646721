@import "vars";

.alert {
	padding: $default-padding;
	border: 1px solid $status-default-color;
	margin: 0px 0px $default-spacing 0px;
	color: $status-default-color;

	&.alert-success {
		color: $status-success-color;
		border-color: $status-success-color;
	}

	&.alert-error {
		color: $status-error-color;
		border-color: $status-error-color;
	}

	&.alert-warning {
		color: $status-warning-color;
		border-color: $status-warning-color;
	}
}

.label {
	&.label-warning {
		color: $status-warning-color;
	}
	
	&.label-error {
		color: $status-error-color;
	}
	
	&.label-success {
		color: $status-success-color;
	}
}

.status {
	display: inline-block;
	border: 1px solid lighten(#ccc, 20%);
	background: #ccc;
	color: #fff;
	font-weight: bold;
	border-radius: 2px;
	box-shadow: 3px 3px 8px rgba(0,0,0,0.8);
	font-size: 10px;
	line-height: 12px;
	padding: 3px 5px;
	min-width: 50px;

	&.status-warning {
		background: $status-warning-color;
		border-color: lighten( $status-warning-color, 20%);
	}
	
	&.status-error {
		background: $status-error-color;
		border-color: lighten( $status-error-color, 20%);
	}
	
	&.status-success {
		background: $status-success-color;
		border-color: lighten( $status-success-color, 20%);
	}
}