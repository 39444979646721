/* import var */
@import "mixins/grid";

.row {
	overflow: hidden;
	margin-left: -1*$default-spacing/2;
	margin-right: -1*$default-spacing/2;
}

@media screen and (max-width: $screen-xs-max) {
	.row-xs-flex {
		@include make-flexrow();
	}
}

@media screen and (min-width: $screen-sm-min) {
	.row-sm-flex {
		@include make-flexrow();
	}
}

@media screen and (min-width: $screen-md-min) {
	.row-md-flex {
		@include make-flexrow();
	}
}

@media screen and (min-width: $screen-lg-min) {
	.row-lg-flex {
		@include make-flexrow();
	}
}

@media screen and (min-width: $screen-xl-min) {
	.row-xl-flex {
		@include make-flexrow();
	}
}

*[class*="col-"] {
	overflow: hidden;
	position: relative;
	float: left;
	min-height: 1px;
	padding-left: $default-spacing/2;
	padding-right: $default-spacing/2;
}

/*
Make columns
*/
@include make-columns('xs', $columns);

@media screen and (min-width: $screen-sm-min) {
	@include make-columns('sm', $columns);
}

@media screen and (min-width: $screen-md-min) {
	@include make-columns('md', $columns);
}

@media screen and (min-width: $screen-lg-min) {
	@include make-columns('lg', $columns);
}

@media screen and (min-width: $screen-xl-min) {
	@include make-columns('xl', $columns);
}

/*
Make visibility helper classes
Available are .hidden-{res}, .invisible-{res}, .visible-{res} (alias of .visible-{res}-block), .visible-{res}-inline-block, .visible-{res}-inline
Replace {res} with xs,sm,md,lg,xl
*/
@media screen and (max-width: $screen-xs-max) {
	@include make-visibility-helper('xs');
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
	@include make-visibility-helper('sm');
}

@media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	@include make-visibility-helper('md');
}

@media screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
	@include make-visibility-helper('lg');
}

@media screen and (min-width: $screen-xl-min) {
	@include make-visibility-helper('xl');
}

/* 
build containers 
if the container width is 100% it will be a responsive container
if a fixed value is given the container will be fixed in the screen center
*/
@include make-container($container-xs-width);

@media screen and (min-width: $screen-sm-min) {
	@include make-container($container-sm-width);
}

@media screen and (min-width: $screen-md-min) {
	@include make-container($container-md-width);
}

@media screen and (min-width: $screen-lg-min) {
	@include make-container($container-lg-width);
}

@media screen and (min-width: $screen-xl-min) {
	@include make-container($container-xl-width);
}

/* 
fluid containers are always 100%
containers can be nested inside fluid containers but loose their padding (page padding is allways $default-spacing)
*/
.container-fluid {

}
